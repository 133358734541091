<template>
  <div>
    <div>
          <div>
            <strong>{{entity.name}}</strong><b-button class="mx-2" variant="outline-primary" size="sm" v-if="permission.add" @click="modalShow = !modalShow" >edit</b-button>
          </div>
          <div>
            {{entity.description}}
          </div>
    </div>
      <b-modal v-model="modalShow" scrollable size="xl" ok-only>
        <div class="my-5">
          <property-input
            :idProp="entity.id"
            :fieldProp="'name'"
            :nameDisplayProp="'Name'"
            :modelProp="'entity'"
            :valueProp="entity.name"
          ></property-input>
        </div>
        <div class="my-5">
          <property-text
            :idProp="entity.id"
            :htmlProp="false"
            :fieldProp="'description'"
            :nameDisplayProp="'Description'"
            :modelProp="'entity'"
            :valueProp="entity.description"
          ></property-text>
        </div>
      </b-modal>
  </div>
</template>

<script>
import _ from 'lodash'
import ac from '@/libs/accesscontrol'
import moment from 'moment'
import PropertyInput from '@/components/sql/PropertyInput.vue'
import PropertyText from '@/components/sql/PropertyText.vue'

export default {
  name: 'Entity',
  components: {
    PropertyInput,
    PropertyText
  },
  props: ['entity'],
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: async function () {
    this.permission.read = ac.can(this.user.acgroups).readAny('regulation').granted
    this.permission.add = ac.can(this.user.acgroups).createAny('regulation').granted
  },
  data () {
    return {
      modalShow: false,
      moment: moment,
      permission: {
        add: false,
        read: false
      },
      usersLoaded: [],
      usersLoading: false,
      usersMatched: [],
      userSearchInput: ''
    }
  },
  methods: {
    regulationEdit: function () {
      this.show.add = false
      this.show.all = false
      this.show.users = true
    },
    regulationEditDone: function (id) {
      this.show.add = false
      this.show.all = true
      this.show.users = false
    },
    userAssign: async function (username) {
      this.usersLoading = true
      try {
        const params = {
          body: {
            payload: {
              regulation_id: this.regulation.id,
              username: username,
              created_by: this.user.username
            }
          }
        }
        const userAssigned = await this.$Amplify.API.put('cosmos', '/regulation-user', params)
        const users = this.regulation.users
        users.push(userAssigned)
        this.regulation.users = users
        this.userSearchInput = ''
        this.$stat.log({ page: 'regulations', action: 'assign user', model: 'regulation', model_id: this.regulation.id, payload: params.body })
      } catch (e) {
        this.$logger.warn('assigning user error: ' + e)
      }
      this.usersLoading = false
    },
    userRemove: async function (id) {
      this.usersLoading = true
      try {
        await this.$Amplify.API.del('cosmos', `/regulation-user/${id}`)
        const users = this.regulation.users
        _.pullAllBy(users, [{ id: id }], 'id')
        this.regulation.users = []
        this.regulation.users = users
        this.$stat.log({ page: 'regulations', action: 'remove user', model: 'regulation', model_id: this.regulation.id })
      } catch (e) {
        this.$logger.warn('assigning user error: ' + e)
      }
      this.usersLoading = false
    }
  }
}
</script>

<style>
</style>
